<script>

import AppHeader from '../components/app-header'
import AppMenu from '../components/app-menu'

export default {

    components: {AppHeader, AppMenu},

    data() {
        return {
            title: '',
        }
    },

    watch: {

    },

    computed: {
    },

    async mounted() {
        
    },

    methods: {

    }
}
</script>

<template>
    <div>
        <AppHeader/>
        <AppMenu/>

        <div class="app-content content">
            <div class="content-wrapper">
              <div class="content-header row">
                <div class="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                  <h3 class="content-header-title mb-0 d-inline-block">Account Profile</h3>
                  <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active">Account Profile
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="content-header-right col-md-4 col-12 d-none d-md-inline-block">
                  <div class="btn-group float-md-right"><a class="btn-gradient-secondary btn-sm white" href="wallet.html">Buy now</a></div>
                </div>
              </div>
              <div class="content-body"><div class="row">
          <div class="col-12 col-md-8">
              <!-- User Profile -->
              <section class="card">
                  <div class="card-content">
                      <div class="card-body">
                          <div class="col-12">
                              <div class="row">
                                  <div class="col-md-2 col-12">
                                      <img src="/assets/images/portrait/medium/avatar-m-1.png" class="rounded-circle height-100" alt="Card image" />
                                  </div>
                                  <div class="col-md-10 col-12">
                                      <div class="row">
                                          <div class="col-12 col-md-4">
                                              <p class="text-bold-700 text-uppercase mb-0">Transactions</p>
                                              <p class="mb-0">12/14</p>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <p class="text-bold-700 text-uppercase mb-0">Last login</p>
                                              <p class="mb-0">2018-04-30 16:44:04</p>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <p class="text-bold-700 text-uppercase mb-0">IP</p>
                                              <p class="mb-0">43.228.229.172</p>
                                          </div>
                                      </div>
                                      <hr/>
                                      <form class="form-horizontal form-user-profile row mt-2" action="index.html">
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="text" class="form-control" id="first-name" value="John" required="" autofocus="">
                                                  <label for="first-name">First name</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="text" class="form-control" id="last-name" value="Doe" required="" autofocus="">
                                                  <label for="last-name">Lats name</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="text" class="form-control" id="user-name" value="johndoe9016" required="" autofocus="">
                                                  <label for="user-name">User name</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="text" class="form-control" id="email-address" value="johndoe9016@gmail.com" required="" autofocus="">
                                                  <label for="email-address">Email</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="password" class="form-control" id="old-password" placeholder="Enter Password" required="" autofocus="">
                                                  <label for="old-password">Old password</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-6">
                                              <fieldset class="form-label-group">
                                                  <input type="password" class="form-control" id="new-password" placeholder="Enter Password" required="" autofocus="">
                                                  <label for="new-password">New password</label>
                                              </fieldset>
                                          </div>
                                          <div class="col-12 text-right">
                                              <button type="submit" class="btn-gradient-primary my-1">Save</button>
                                          </div>
                                      </form>
                                      <h5>Referral Link</h5>
                                      <hr/>
                                      <form class="form-horizontal form-referral-link row mt-2" action="index.html">
                                          <div class="col-12">
                                              <fieldset class="form-label-group">
                                                  <input type="text" class="form-control" id="referral-link" value="https://themeforest.net/user/pixinvent/portfolio?ref=PIXINVENT" required="" autofocus="">
                                                  <label for="first-name">Referral link</label>
                                              </fieldset>
                                          </div>
                                      </form>
                                      <p>This is your Crypto ICO referral link. You can use it to share the project with your friends and other interested parties. If any of them sign up with this link, they will get reward amounts to 1% and will be added to your referral program. Your reward amounts to 5.0% of all ICI tokens purchased by your referrals.</p>
                                      <h5 class="mt-3">Security</h5>
                                      <hr/>
                                      <div class="row">
                                          <div class="col-9">
                                              <p>Two-factor authorization</p>
                                          </div>
                                          <div class="col-3">
                                              <input type="checkbox" id="switcherySize2" class="switchery" data-size="sm" checked/>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-9">
                                              <p>Login notification</p>
                                          </div>
                                          <div class="col-3">
                                              <input type="checkbox" id="switcherySize2" class="switchery" data-size="sm" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
          <div class="col-12 col-md-4">
              <div class="card">
                  <div class="card-header">
                      <h6 class="card-title text-center">ICO Tokens</h6>
                  </div>
                  <div class="card-content collapse show">
                      <div class="card-body">
                          <div class="text-center row clearfix mb-2">
                              <div class="col-12">
                                  <i class="icon-layers font-large-3 bg-warning bg-glow white rounded-circle p-3 d-inline-block"></i>
                              </div>
                          </div>
                          <h3 class="text-center">3,458.88 CIC</h3>
                      </div>
                      <div class="table-responsive">
                          <table class="table table-de mb-0">
                              <tbody>
                                  <tr>
                                      <td>CIC Token</td>
                                      <td><i class="icon-layers"></i> 3,258 CIC</td>
                                  </tr>
                                  <tr>
                                      <td>CIC Referral</td>
                                      <td><i class="icon-layers"></i> 200.88 CIC</td>
                                  </tr>
                                  <tr>
                                      <td>CIC Price</td>
                                      <td><i class="cc BTC-alt"></i> 0.0001 BTC</td>
                                  </tr>
                                  <tr>
                                      <td>Raised BTC</td>
                                      <td><i class="cc BTC-alt"></i> 2154 BTC</td>
                                  </tr>
                                  <tr>
                                      <td>Raised USD</td>
                                      <td><i class="la la-dollar"></i> 4.52 M</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="card">
              <div class="card-header">
                  <h6 class="card-title text-center">Token sale progress</h6>            
              </div>
              <div class="card-content collapse show">
                  <div class="card-body">
                      <div class="font-small-3 clearfix">
                          <span class="float-left">$0</span>
                          <span class="float-right">$5M</span>
                      </div>
                      <div class="progress progress-sm my-1 box-shadow-2">
                          <div class="progress-bar bg-warning" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div class="font-small-3 clearfix">
                          <span class="float-left">Distributed <br> <strong>6,235,125 CIC</strong></span>
                          <span class="float-right text-right">Contributed  <br> <strong>5478 ETH | 80 BTC</strong></span>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
              </div>
            </div>
          </div>

    </div>
</template>